// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelt-js": () => import("./../../../src/pages/aktuelt.js" /* webpackChunkName: "component---src-pages-aktuelt-js" */),
  "component---src-pages-api-sok-js": () => import("./../../../src/pages/api-sok.js" /* webpackChunkName: "component---src-pages-api-sok-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-oss-js": () => import("./../../../src/pages/kontakt-oss.js" /* webpackChunkName: "component---src-pages-kontakt-oss-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-campaign-template-js": () => import("./../../../src/templates/campaignTemplate.js" /* webpackChunkName: "component---src-templates-campaign-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

